import * as React from 'react'
import { Layout } from '../components/layout'
import { useStaticQuery, graphql } from 'gatsby'
import { Painting } from '../components/painting'
import * as styles from '../components/paintingCategory.module.css'
import { Helmet } from 'react-helmet'

export default function CommissionsPage(props) {

	const commissionsQuery = useStaticQuery(graphql`
		query MyCommissionsQuery {
			allMarkdownRemark(filter: {frontmatter: {category: {eq: "Commissions"}}}) {
				edges {
					node {
						frontmatter {
							image {
								childImageSharp {
									gatsbyImageData
								}
							}
							category
							title
						}
					}
				}
			}
		}
	`)

	const nodes = commissionsQuery.allMarkdownRemark.edges

	const paintings = nodes.map(painting => {
		return (
			<Painting
				img={painting.node.frontmatter.image.childImageSharp.gatsbyImageData}
				title={painting.node.frontmatter.title}
			></Painting>
		)
	})

	return (
		<Layout>
			<Helmet>
				<title>Commissions | Bethany Rachelle</title>
			</Helmet>
			<div className={styles.category}>
				{paintings}
			</div>
		</Layout>
	)
}